<template>
    <div class="row mx-0 border-bottom py-1">
        <div class="col-6 my-auto">
            <div class="d-middle" style="padding-left: 36px;">
                <i class="icon-menu handle cr-grabbing" />
                <p class="f-12"> {{ fullData.nombre }} </p>
            </div>
        </div>
        <div class="col-3 my-auto">
            <div class="d-middle-center f-12">
                <div class="border br-5 bg-f5 py-1" style="width: 100px;">
                    <p class="text-center text-muted f-13">{{fullData.peso}}</p>
                </div>
                <div class="ml-2"> % </div>
                <!-- <el-input v-model="fullData.peso" size="small"  placeholder="Peso actividad" class="w-80 text-center" @change="updatePeso($event, fullData)" /> -->
            </div>
        </div>
        <div class="col-auto ml-auto">
            <div class="d-flex icon-option">
                <el-popover v-model="visibleEditActivity" placement="bottom-end" width="360" trigger="click" class="px-0">
                    <div class="row mx-0 justify-content-center f-600 text-general">
                        Editar actividad
                    </div>
                    <hr />
                    <div class="row mx-0 justify-content-center my-2">
                        <div class="col-10 my-2">
                            <p class="input-label-top">Nombre de la lista</p>
                            <el-input v-model="activityName" placeholder="Nombre de la lista" size="small" class="w-100" />
                        </div>
                        <div class="col-10 my-2">
                            <p class="input-label-top">Peso</p>
                            <ValidationProvider v-slot="{errors}" name="peso" rules="required">
                                <el-input-number v-model="activityPeso" :min="0" :max="(100-totalPeso)+activityPeso" :controls="false" placeholder="Peso actividad" size="small" class="w-100" />
                                <vee-error :text="errors[0]" />
                            </ValidationProvider>
                        </div>
                    </div>
                    <hr />
                    <div class="text-right m-0">
                        <button class="btn btn-cerrar f-12" @click="visibleEditActivity = !visibleEditActivity">Cerrar</button>
                        <button class="btn btn-crear f-12 ml-2" @click="onEditActivity">Editar</button>
                    </div>
                    <el-tooltip slot="reference" content="Editar" effect="light" placement="left" visible-arrow>
                        <i class="icon-pencil f-20 cr-pointer" />
                    </el-tooltip>
                </el-popover>
                <el-tooltip content="Eliminar" effect="light" placement="right" visible-arrow>
                    <i class="icon-trash-can-outline f-20 cr-pointer" @click="deleteActivity(fullData.id)" />
                </el-tooltip>
            </div>
        </div>
        <modal-eliminar ref="deleteActivity" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?" @delete="handleDeleteAcivity" />
    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props: {
        fullData:{
            type: Object
        },
        totalPeso:{
            type: Number,
            default: 0
        },
        indexList:{
            type: Number,
            default: -1
        },
        indexAct:{
            type: Number,
            default: -1
        },
    },
    data() {
        return {
            activityName: '',
            visibleEditActivity: false,
            idActivityToDelete: '',
            activityPeso: 0
        }
    },
    watch: {
        visibleEditActivity(val) {
            if (val) {
                this.activityName = this.fullData.nombre
                this.activityPeso = this.fullData.peso
            }
        }
    },
    methods: {
        ...mapActions({
            ACTION_EDIT_ACTIVITY_CHECK_LIST: 'configurar/obras/lista_chequeo/ACTION_EDIT_ACTIVITY_CHECK_LIST',
            ACTION_DELETE_ACTIVITY: 'configurar/obras/lista_chequeo/ACTION_DELETE_ACTIVITY',
        }),
        updatePeso(e, val){
            console.log('data data',val);
        },
        onEditList(){
            this.visibleEditList = !this.visibleEditList
        },
        onCreateActivity(){
            this.visibleCreateActivity = !this.visibleCreateActivity
        },
        async onEditActivity(){
            let obj = {
                id: this.fullData.id,
                nombre: this.activityName,
                id_configuracion_lista_chequeo: this.fullData.id_configuracion_lista_chequeo,
                peso:this.activityPeso,
                id_lista_chequeo:this.fullData.id_lista_chequeo,

            }
            await this.ACTION_EDIT_ACTIVITY_CHECK_LIST({obj, indexList: this.indexList, indexAct: this.indexAct})
            this.visibleEditActivity = !this.visibleEditActivity
        },
        deleteActivity(id){
            this.idActivityToDelete = id
            this.$refs.deleteActivity.toggle()
        },
        async handleDeleteAcivity(){
            await this.ACTION_DELETE_ACTIVITY({
                id_actividad: this.idActivityToDelete,
                id_configuracion_lista_chequeo: this.fullData.id_configuracion_lista_chequeo,
                indexList: this.indexList,
                indexAct: this.indexAct
            })
        }
    },
}
</script>

<style>

</style>