<template>
    <div class="row mx-0 bg-f5 listado-check-list">
        <div class="col-6 my-auto">
            <div class="d-middle">
                <i class="icon-menu  handle mr-3 cr-grabbing" />
                <p class="f-14 text-general f-600"> {{ checkName}} </p>
            </div>
        </div>
        <div class="col-3 my-auto">
            <p class="text-general text-center f-14 f-600">Peso de actividad</p>
        </div>
        <div class="col-auto ml-auto">
            <div class="d-flex icon-option">
                <ValidationObserver ref="validatorCreateAcivity">
                    <el-popover v-model="visibleCreateActivity" placement="bottom-end" width="360" trigger="click" class="px-0">
                        <div class="row mx-0 justify-content-center f-600 text-general">
                            Crear actividad
                        </div>
                        <hr />
                        <div class="row mx-0 justify-content-center my-2">
                            <div class="col-10 my-2">
                                <p class="input-label-top">Nombre de la Actividad</p>
                                <ValidationProvider v-slot="{errors}" name="nombre actividad" rules="required">
                                    <el-input v-model="subactivityName" placeholder="Nombre de la actividad" size="small" class="w-100" />
                                    <vee-error :text="errors[0]" />
                                </ValidationProvider>
                            </div>
                            <div class="col-10 my-2">
                                <p class="input-label-top">Peso</p>
                                <ValidationProvider v-slot="{errors}" name="peso" rules="required">
                                    <el-input-number v-model="activityPeso" :min="0" :max="100-totalPeso" :controls="false" placeholder="Peso actividad" size="small" class="w-100" />
                                    <vee-error :text="errors[0]" />
                                </ValidationProvider>
                            </div>
                        </div>
                        <hr />
                        <div class="text-right m-0">
                            <button class="btn btn-cerrar f-12" @click="visibleCreateActivity = !visibleCreateActivity">Cerrar</button>
                            <button class="btn btn-crear f-12 ml-2" @click="onCreateActivity">Guardar</button>
                        </div>
                        <i slot="reference" class="icon-plus-box f-20 cr-pointer" />
                    </el-popover>
                </ValidationObserver>
                <ValidationObserver ref="validatorEditList">
                    <el-popover v-model="visibleEditList" placement="bottom-end" width="360" trigger="manual" class="px-0">
                        <div class="row mx-0 justify-content-center f-600 text-general">
                            Editar lista de chequeo
                        </div>
                        <hr />
                        <div class="row mx-0 justify-content-center my-2">
                            <div class="col-10 my-2">
                                <p class="input-label-top">Nombre de la lista</p>
                                <ValidationProvider v-slot="{errors}" name="nombre lista" rules="required">
                                    <el-input v-model="editcheckListItem.nombre" placeholder="Nombre de la lista" size="small" class="w-100" />
                                    <vee-error :text="errors[0]" />
                                </ValidationProvider>
                            </div>
                        </div>
                        <hr />
                        <div class="text-right m-0">
                            <button class="btn btn-cerrar f-12" @click="visibleEditList = !visibleEditList">Cerrar</button>
                            <button class="btn btn-crear f-12 ml-2" @click="onEditList">Guardar</button>
                        </div>
                        <el-tooltip slot="reference" content="Editar" effect="light" visible-arrow>
                            <i class="icon-pencil f-20 cr-pointer" @click="editItem()" />
                        </el-tooltip>
                    </el-popover>
                </ValidationObserver>
                <el-tooltip content="Eliminar" effect="light" visible-arrow>
                    <i class="icon-trash-can-outline f-20 cr-pointer" @click="deleteList" />
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        fullData:{
            type: Object
        },
        totalPeso:{
            type: Number,
            default: 0
        },
        checkName: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            visibleCreateActivity: false,
            visibleEditActivity: false,
            visibleEditList: false,
            listName: '',
            activityName: '',
            editActivityName: '',
            subactivityName: '',
            activityPeso: '',
        }
    },
    computed: {
        editcheckListItem:{
            get(){
                return this.$store.getters['configurar/obras/lista_chequeo/editCheckList']
            },
            set(val){
                this.$store.commit('configurar/obras/lista_chequeo/set_edit_checkList', val)
            }
        },
    },
    methods: {
        ...mapActions({
            ACTION_EDIT_CHECK_LIST: 'configurar/obras/lista_chequeo/ACTION_EDIT_CHECK_LIST',
            ACTION_DELETE_CHECK_LIST: 'configurar/obras/lista_chequeo/ACTION_DELETE_CHECK_LIST',
            ACTION_CREATE_ACTIVITY_CHECK_LIST: 'configurar/obras/lista_chequeo/ACTION_CREATE_ACTIVITY_CHECK_LIST',
        }),
        async onCreateActivity(){
            const valid = await this.$refs.validatorCreateAcivity.validate()
            if (valid) {
                let obj = {
                    id_lista_chequeo: this.fullData.id,
                    nombre: this.subactivityName,
                    peso: this.activityPeso
                }
                await this.ACTION_CREATE_ACTIVITY_CHECK_LIST(obj)
                this.visibleCreateActivity = !this.visibleCreateActivity
                this.$refs.validatorCreateAcivity.reset()
                this.subactivityName = ''
                this.activityPeso = ''
            }
        },
        onEditActivity(){
            this.visibleEditActivity = !this.visibleEditActivity
        },
        deleteActivity(){
            //this.$refs.deleteActivity.toggle()
        },
        deleteList(){
            this.$emit('deleteCheckListItem')
        },
        async onEditList(){
            const valid = await this.$refs.validatorEditList.validate()
            if (valid) {
                await this.ACTION_EDIT_CHECK_LIST()
                this.visibleEditList = !this.visibleEditList
            }
            
        },
        editItem(){
            this.visibleEditList = !this.visibleEditList
            this.editcheckListItem.id = this.fullData.id,
            this.editcheckListItem.nombre = this.fullData.nombre,
            this.editcheckListItem.actividades = this.fullData.actividades
            //this.$store.commit('configurar/obras/lista_chequeo/set_edit_checkList', this.fullData)
        }
    }

}
</script>

<style>

</style>