<template>
    <section class="config-obras-lista-chequeo">
        <div class="row">
            <div class="col-12 col-lg-10">
                <!-- title + addList -->
                <titulo-divisor titulo="Listas de chequeo">
                    <ValidationObserver ref="validatorCreateList">
                        <el-popover v-model="visibleCreateList" placement="bottom-end" width="360" trigger="click" class="px-0">
                                <div class="row mx-0 justify-content-center f-600 text-general">
                                    Crear lista de chequeo
                                </div>
                                <hr />
                                <div class="row mx-0 justify-content-center my-2">
                                    <div class="col-11 my-2">
                                        <p class="input-label-top">Nombre de la lista</p>
                                        <ValidationProvider v-slot="{errors}" name="nombre lista" rules="required">
                                            <el-input v-model="checkListItem.nombre" placeholder="Nombre de la lista" size="small" class="w-100" />
                                            <vee-error :text="errors[0]" />
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <hr />
                                <div class="text-right m-0">
                                    <button class="btn btn-cerrar f-12" @click="visibleCreateList = !visibleCreateList">Cerrar</button>
                                    <button class="btn btn-crear f-12 ml-2" @click="createCheckList">Crear</button>
                                </div>
                                <el-tooltip slot="reference" content="Crear lista" effect="light" visible-arrow>
                                    <i class="icon-plus-circle text-general f-30 cr-pointer" @click="$refs.validatorCreateList.reset()" />
                                </el-tooltip>
                        </el-popover>
                    </ValidationObserver>
                </titulo-divisor>
            </div>
        </div>
        <div class="row">
            <div class="col-10 col-lg-10">
                <section class="mb-4">
                    <!-- calendars list -->
                    <draggable tag="div" :list="checkLists" class="row" handle=".handle" @change="updatePositionList($event)">
                        <div v-for="(data,index1) in checkLists" :key="index1" class="col-12 mb-4 mt-3">
                            <check-list-item :check-name="data.nombre" :totalPeso="getTotalPeso(data)" :full-data="data" @deleteCheckListItem="deleteList(data.id)" @reload="listCheckList" />
                            <draggable tag="div" :list="data.actividades" class="" handle=".handle" @change="updatePositionActivitiy($event)">
                                <activityCheckList v-for="(act, idx) in data.actividades" :key="act.id" :indexList="index1" :indexAct="idx" :totalPeso="getTotalPeso(data)" :full-data="act" @reload="listCheckList" />
                            </draggable>
                        </div>
                    </draggable>
                </section>
            </div>
        </div>
        <!-- partials -->
        <modal-eliminar ref="deleteList" title="Eliminar lista de chequeo" mensaje="¿Está seguro que quiere eliminar esta lista de chequeo?" @delete="handleDeleteCheckListItem" />
        <modal-eliminar ref="deleteActivity" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?" />
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import checkListItem from './components/checkListItem.vue'
import activityCheckList from './components/activityCheckList.vue'
export default {
    components:{
        checkListItem,
        activityCheckList,
    },
    data(){
        return{
            visibleCreateList: false,
            listName: '',
            activityName: '',
            editActivityName: '',
            visibleEditList: false,
            visibleCreateActivity: false,
            visibleEditActivity: false,
            options: [
                {
                    value: 'Option 1',
                    label: 'Option 1'
                }, 
                {
                    value: 'Option 2',
                    label: 'Option 2'
                }, 
                {
                    value: 'Option 3',
                    label: 'Option 3'
                }, 
                {
                    value: 'Option 4',
                    label: 'Option 4'
                }, 
                {
                    value: 'Option 5',
                    label: 'Option 5'
                }
            ],
            valueSelect: '',
            idCheckListToDelete: null
        }
    },
    mounted(){
        this.listCheckList()
    },
    computed: {
        ...mapGetters({
            checkLists: 'configurar/obras/lista_chequeo/checkLists',  
        }),
        checkListItem:{
            get(){
                return this.$store.getters['configurar/obras/lista_chequeo/checkList']
            },
            set(val){
                this.$store.commit('configurar/obras/lista_chequeo/set_check_list', val)
            },
        },
    },
    methods:{
        ...mapActions({
            ACTION_GET_CHECK_LISTS: 'configurar/obras/lista_chequeo/ACTION_GET_CHECK_LISTS',
            ACTION_CREATE_CHECK_LIST: 'configurar/obras/lista_chequeo/ACTION_CREATE_CHECK_LIST',
            ACTION_DELETE_CHECK_LIST: 'configurar/obras/lista_chequeo/ACTION_DELETE_CHECK_LIST',
            ACTION_UPDATE_POSITION_ACTIVITY: 'configurar/obras/lista_chequeo/ACTION_UPDATE_POSITION_ACTIVITY',
            ACTION_UPDATE_POSITION_CHECK_LIST: 'configurar/obras/lista_chequeo/ACTION_UPDATE_POSITION_CHECK_LIST',
        }),
        async listCheckList(){
            await this.ACTION_GET_CHECK_LISTS()
        },
        async createCheckList(){
            const valid = await this.$refs.validatorCreateList.validate()
            if (valid) {
                await this.ACTION_CREATE_CHECK_LIST()
                this.visibleCreateList = !this.visibleCreateList
            }
        },
        deleteList(id){
            this.idCheckListToDelete = id
            this.$refs.deleteList.toggle()
        },
        async handleDeleteCheckListItem(){
            await this.ACTION_DELETE_CHECK_LIST(this.idCheckListToDelete)
        },
        onEditList(){
            this.visibleEditList = !this.visibleEditList
        },
        onCreateActivity(){
            this.visibleCreateActivity = !this.visibleCreateActivity
        },
        onEditActivity(){
            this.visibleEditActivity = !this.visibleEditActivity
        },
        deleteActivity(){
            this.$refs.deleteActivity.toggle()
        },
        async updatePositionActivitiy(eve){
            let actividades = this.checkLists.find(e => e.id == eve.moved.element.id_lista_chequeo)
            let obj = {
                "id_lista_chequeo": eve.moved.element.id_lista_chequeo,
                "actividades": actividades.actividades.map((e, idx) => {
                    return {
                        id_configuracion_control_piso_lista_chequeo_actividades: e.id,
                        posicion: idx+1
                    }
                })
            }
            await this.ACTION_UPDATE_POSITION_ACTIVITY(obj)
            //await this.ACTION_GET_CHECK_LISTS()
        },
        async updatePositionList(eve){
            let payload = this.checkLists.map((e, idx) => {
                return {
                    "id_configuracion_control_piso_lista_chequeo":e.id,
                    "posicion":idx+1
                }

            })
            await this.ACTION_UPDATE_POSITION_CHECK_LIST({"listas_chequeo":payload})
        },
        getTotalPeso(data){
            let totalPeso = 0
            data.actividades.forEach(e => {
                totalPeso += e.peso
            });
            return totalPeso
        }
    }

}
</script>

<style lang="scss" scoped>
.config-obras-lista-chequeo{
    .handle{
        cursor: grabbing;
    }
    .icon-ray-start-arrow, .icon-clock-outline{
        &::before{
            margin-left: 0px !important;
        }
    }
}
</style>
